import React, { Component } from 'react';

import BaseEntities from 'components/BaseEntities';
import MessageList from './Entities/MessageList';

export default class Entities extends Component {

    static getTemplates() {
        return Object.assign(BaseEntities.getTemplates(), {
            "message_list": MessageList,
            "message_grouped_list": MessageList
        });
    }

    render() {
        return <BaseEntities {...this.props} getTemplates={Entities.getTemplates}/>;
    }
}

