import React, {Component, useState } from 'react';
import MessageItemMixin from './MessageItemMixin';
import ListItemMixin from 'components/BaseEntities/ListItemMixin';
import uniFetch from "../../utils/uniFetch";


export default class MessageList extends Component {

  componentDidMount() {
    const filter_button = document.createElement("div")
    const filter = document.querySelector('.sidebar-filter')

    const filterParam = 'filter_hide=true';

    function toggleParam(param, currentUrl) {
      let newUrl = ''
      if (currentUrl.indexOf(param) === -1) {
        var separator = currentUrl.indexOf('?') !== -1 ? '&' : '?';
        newUrl = currentUrl + separator + filterParam;
      } else {
        const regex = new RegExp('([?&])' + filterParam);

        newUrl = currentUrl.replace(regex, function (match, p1) {
          return p1 === '?' || p1 === '&' ? p1 : '';
        });
        newUrl = newUrl.replace(/[?]$/, '');
      }
      history.pushState(null, null, newUrl);
    }

    if (window.location.href.indexOf(filterParam) !== -1) {
      filter.classList.add('ex-hidden')
    }

    filter_button.innerHTML = `                    
                           <button class="ex-btn ex-btn-default filter-button hidden-xs">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                    <g transform="rotate(90 12 12)">
                        <path fill="currentColor"
                              d="M20 8.18V3a1 1 0 0 0-2 0v5.18a3 3 0 0 0 0 5.64V21a1 1 0 0 0 2 0v-7.18a3 3 0 0 0 0-5.64ZM19 12a1 1 0 1 1 1-1a1 1 0 0 1-1 1Zm-6 2.18V3a1 1 0 0 0-2 0v11.18a3 3 0 0 0 0 5.64V21a1 1 0 0 0 2 0v-1.18a3 3 0 0 0 0-5.64ZM12 18a1 1 0 1 1 1-1a1 1 0 0 1-1 1ZM6 6.18V3a1 1 0 0 0-2 0v3.18a3 3 0 0 0 0 5.64V21a1 1 0 0 0 2 0v-9.18a3 3 0 0 0 0-5.64ZM5 10a1 1 0 1 1 1-1a1 1 0 0 1-1 1Z"/>
                    </g>
                </svg>
            </button>`
    filter_button.addEventListener('click', () => {
      toggleParam(filterParam, window.location.href)
      filter.classList.toggle('ex-hidden')
    })

    const parent = document.querySelector('.main-col__top.maincol-top');
    parent.insertBefore(filter_button, parent.firstChild)
  }

  render() {
    const {items, actions, loading, descriptions, meta} = this.props;
    let entities_class = "entities list-items";
    entities_class = loading ? entities_class + " ex-state-loading" : entities_class;
    return (
      <div className={entities_class}>
        {items.map(
          (child, i) =>
            <MessageListItem
              key={i}
              data={child}
              actions={actions}
              loading={loading}
              descriptions={descriptions}
              position={i}
              meta={meta}
            />
        )}
      </div>
    );
  }
}


function MessageTypeSelect(props) {
    const {selected, apiUrl} = props;
    const req_param = 'message_type';
    const [current_type, setCurrentType] = useState(selected);
    const [updated, setUpdated] = useState(true);
    const option_list = [
        {"title": "Устранение проблемы", "value": "2"},
        {"title": "Повторная жалоба", "value": "3"},
        {"title": "Критика власти", "value": "4"},
        {"title": "Просьба проинформировать", "value": "5"},
        {"title": "Предложение/инициатива/идея", "value": "6"},
        {"title": "Адресная помощь", "value": "7"},
        {"title": "Семьи военнослужащих", "value": "8"},
        {"title": "--------------------------------", "value": "0"},
        {"title": "Комментарии", "value": "10"},
        {"title": "Cтикеры/смайлики", "value": "12"},
        {"title": "Благодарности", "value": "11"},
        {"title": "Соболезнования", "value": "13"},
        {"title": "--------------------------------", "value": "0"},
        {"title": "Ответы", "value": "9"},
        {"title": "Неопределенное сообщение", "value": "1"},
    ];

    const handleChangeMessageType = (e) => {
        if(e.target.value!=="0"){
            setCurrentType(e.target.value);
            if (!updated){
                setUpdated(true);
            }
        }
    }

    const handleCancelChangeType = (e) => {
        setCurrentType(selected);
        if (!updated){
            setUpdated(true);
        }
    }

    const handleSaveType = (e) => {

      let rdata = {};

      rdata[req_param] = current_type;

      let request = uniFetch(apiUrl,'PATCH',rdata);
        request.then(response => {
            setUpdated(false);
        }).catch(response => {
            let err_message = `Ошибка обновления из API по URL: ${apiUrl}. Ошибка: ${response}`;
            window.alert(err_message);
        })
    }

    return <div className="row pb-2">
            <div className="col-md-9">
                  <select
                      className="form-select"
                      name="message-type"
                      value={current_type}
                      onChange={e => handleChangeMessageType(e)}>
                    {option_list.map((child, i) => (
                      <option value={child.value} key={i}>
                        {child.title}
                      </option>
                    ))}
                  </select>
             </div>
           { (updated && (selected!==current_type)) ?
              <div className="col-md-3">
                  <button className="btn btn-success btn-sm btn-save me-2"
                          onClick={e => handleSaveType(e)}>
                      <i className="fa fa-check" title="Сохранить" aria-hidden="true"></i>
                  </button>
                  <button className="btn btn-danger btn-sm btn-cancel"
                          onClick={e => handleCancelChangeType(e)}>
                      <i className="fa fa-times" title="Отмена" aria-hidden="true"></i>
                  </button>
              </div>
              : null
           }
        </div>
}

const MessageHumanApproveButtons = ({human_approved, apiUrl, human_approved_buttons, entity_id}) => {
    const [currentChoice, setCurrentChoice] = useState(human_approved)

    const handleSaveHumanApproved = (e, value) => {

        let rdata = {human_approved: value};

        let request = uniFetch(apiUrl,'PATCH', rdata);
        request.then(response => {
            setCurrentChoice(value)
        }).catch(response => {
            let err_message = `Ошибка обновления из API по URL: ${apiUrl}. Ошибка: ${response}`;
            window.alert(err_message);
        })
    }

    const getMessageButton = (buttonClass, iconClass, value, title) => {
        const intValue = Number(value)
        const buttonClassName = `message-button ex-btn ${buttonClass} ${intValue === currentChoice ? 'active' : ''}`;
        return (
            <div
                className={buttonClassName}
                title={title}
                key={'human-approve-button-' + entity_id + '-' + intValue}
                onClick={e => handleSaveHumanApproved(e, intValue)}
            >
                <i className={iconClass}></i>
            </div>
        );
    };

    return (
        <div className="ex-btn-group message-button-container justify-content-end">
            {Object.keys(human_approved_buttons).map((k) => (
                getMessageButton(human_approved_buttons[k].button_class, human_approved_buttons[k].icon_class, k, human_approved_buttons[k].title)
              ))}
        </div>
    )
}

class MessageListItem extends MessageItemMixin(ListItemMixin(Component)) {
    getDescriptionText() {
        const {data, descriptions} = this.props,
            descr = descriptions[data.id] || descriptions?.groups[data.id];
        return descr && descriptions.opened[data.id] ? descr.description : data.extra.short_description;
  }

  getItemBlock(url, data, title, descrText, descriptions, groupSize=0){
    const characteristics = data.short_characteristics;
    const createdAtDate = new Date(data.extra.created_at).toLocaleDateString();
    const createdAtTime = new Date(data.extra.created_at).toLocaleTimeString();

    return (
      <div className="col-md-9">
        <div className="row">
            <div className="col-md-7">
                <a href={url}>
                    <h4>{title}</h4>
                </a>
            </div>
            {groupSize === 0 &&
                <div className="col-md-5 col-sm-12 mb-2">
                    <MessageHumanApproveButtons human_approved={data.extra.human_approved}
                                                human_approved_buttons={data.extra.human_approved_buttons}
                                                entity_id={data.id}
                                                apiUrl={data.entity_url} key={'human_approve' + data.id}
                    />
                </div>
            }
        </div>
          <MessageTypeSelect selected={data.extra.message_type.toString()}
                             apiUrl={data.entity_url} key={'message_type' + data.id}
          />
          <p>{descrText}</p>
          <div className="ex-description-wrapper">
          <ul className="ex-attrs">
            {characteristics.map((child, i) => (
              <li data-path={child.path} key={i} data-view-class={child.view_class.join(" ")}>
                <strong>{child.name}:&nbsp;</strong>
                {child.values.join(';')}
              </li>
            ))}
          </ul>
        </div>
        <p className="date_time"><i className="fa fa-calendar"/>&nbsp;
          {createdAtDate}&nbsp;{createdAtTime}&nbsp;<i className="fa fa-user-o"/>&nbsp;{data.extra.author}
        </p>
      </div>
    )
  }

  render() {
    const {data, meta, descriptions} = this.props,
      url = data.extra?.url || data.entity_url,
      groupSize = data.extra?.group_size || 0,
      descrText = this.getDescriptionText();

    const groupDigit = groupSize ? (
      <div className="ex-pack">
        <span className="ex-digit">{groupSize}</span>
        <div>
          <div>
            <div/>
          </div>
        </div>
      </div>
    ) : null

    let characteristics = data.characteristics || [],
      marks = data.short_marks || [];

    // let related_data_marts = [];
    if (descriptions[data.id]) {
      characteristics = descriptions[data.id].characteristics || [];
      marks = descriptions[data.id].marks || [];
      // related_data_marts = descriptions[data.id].marks || [];
    }

    const className = "ex-catalog-item list-item" + (groupSize ? " ex-catalog-item-variants" : "") +
      (descriptions.opened[data.id] ? " ex-state-description" : "");

    const exAttrs = this.getExAttrs(data, characteristics),
      // exTags = this.getExTags(marks),
      // descriptionBalloon = this.getDescriptionBaloon(data, characteristics, marks, descriptions, exAttrs, exTags) || "",
      title = groupSize && !meta.alike ? data.extra.group_name : data.entity_name,
      // itemBlock = this.getItemBlock(url, data, title, descriptionBalloon),
      itemBlock = this.getItemBlock(url, data, title, descrText, descriptions, groupSize),
      itemContent = this.getItemContent(url, data, itemBlock, marks);

    return (
      <div className={className}
           onMouseOver={e => this.handleMouseOver(e)}
           onMouseOut={e => this.handleMouseOut(e)}
           style={{minHeight: this.state.minHeight}}>
        {groupDigit}
        {itemContent}
      </div>
    );
  }
}
