import React from 'react';


const MessageItemMixin = Base => class extends Base {

  getExAttrs(data, characteristics) {

    let annotations = {};
    if (data.extra) {
      for (const [key, val] of Object.entries(data.extra)) {
        if (val instanceof Object && 'name' in val && 'value' in val)
          annotations[key] = val;
      }
    }

    return (
      <ul className="ex-attrs">
        {characteristics.map(
          (child, i) =>
            <li data-path={child.path} key={i}
                data-view-class={child.view_class.join(" ")}>
              <strong>{child.name}:&nbsp;</strong>
              {child.values.join("; ")}
            </li>
        )}
        {Object.keys(annotations).length !== 0 &&
        <li className="annotation">
          {Object.keys(annotations).map(
            (key, i) =>
              annotations[key].value != null ?
                <div key={i}>
                    <strong>{annotations[key].name}:&nbsp;</strong>
                    {annotations[key].value instanceof Array ?
                      annotations[key].value.map((val, k) => <span key={k}>{val};&nbsp;</span>)
                    :
                      <span key={key}>{annotations[key].value}</span>
                    }
                  </div> :
                  ''
          )}
        </li>
        }
      </ul>
    )
  }
};

export default MessageItemMixin
